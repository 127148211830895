@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

@keyframes float {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }

    50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey(-20px);
    }

    100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
}

//   Variables
$primary: #326ecd;
$secondary: #f1a546;
$light: #fff;
$dark: #000;
$text: #212D40;
$navbar-height: 120px;

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;

    .row{
        margin-left: 0;
        margin-right: 0;
    }
}

html {
    width: 100vw;
}

.w-60 {
    width: 60%;
}

.w-85 {
    width: 85% !important;
}

.w-95 {
    width: 95%;
}
.w-80 {
    width: 80%;
}

body {
    overflow-x: hidden;
    background-color: $text;
    width: 100vw;

    & > div {
        width: 100vw;
    }
}

a {
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
}

.button {
    padding: 12px 30px;
    border-width: 2px;
    border-style: solid;
    color: $light;
    background-size: 200%;
    transition: .5s ease-out;
    min-height: 34px;
    min-width: max-content;
    height: max-content;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;


    &:hover {
        border-image: linear-gradient(to right, $primary, $primary) 1;
        border-width: 2px;
        color: $light;
    }
    &-primary {
        border-image: linear-gradient(to right, $secondary, $primary) 1;
    
        &-reverse {
          border-image: linear-gradient(to right, $primary, $secondary) 1;
        }
        &-blue{
          border-image: linear-gradient(to right, #5194A9, #72C1CC) 1;
        }
    }

    &-light {
        border-image: linear-gradient(to right, $secondary, $light) 1;
    }

    &-def {
        border-image: linear-gradient(to right, $secondary, $secondary) 1;
    }
}
.ibutton {
    // line-height: 18px;
    background: -webkit-linear-gradient(45deg, $secondary, $light);
    font-size: 18px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    position: relative;
    bottom: 0;
}

/* line */
.line {
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, $secondary 0%, $primary 100%);
}

/* Start of Navbar */
.navbar {
    height: $navbar-height;
    width: 100vw;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    font-size: 17px;
    padding: 0;
    transition: 0.35s all;

    &:hover {
        background-color: #f1f1f1;
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .18);

        .logo:nth-child(1) {
            display: none;
        }

        .logo:nth-child(2) {
            display: block;
        }

        #navbarNav li a {
            color: $dark;
        }

        .navbar-toggler {
            color: rgba($color: $dark, $alpha: .5);
        }
    }

    .container-fluid {
        height: $navbar-height;
    }

    &-brand {
        height: 100%;
        width: 288px;
        padding: 0;
        margin: 0;
        background-color: transparent;
    }

    &-collapse {
        flex-grow: 3;
    }

    &-toggler {
        background-color: rgba($color: $dark, $alpha: 0.5);
        // background: linear-gradient(90deg, rgba($color: $primary, $alpha: 0.75) 0%, rgba($color: $secondary, $alpha: 0.75) 100%);
        border-radius: 20px;

        &[aria-expanded="true"] {
            &::before {
                content: '';
                width: 100vw;
                height: $navbar-height;
                position: fixed;
                z-index: -2;
                background: rgba($color: $light, $alpha: 0.75);
                right: 0;
                left: 0;
                top: 0;
            }
        }
    }

    #navbarNav {
        list-style: none;
        flex-direction: row;
        align-items: center;
        transition: all 0.35s;

        li {
            display: flex;
            height: 40%;
            align-items: center;

            a {
                color: $light;
                padding: 48px 30px;
                font-size: 1rem;
                font-weight: 600;

                &:hover,
                &:active {
                    color: $secondary  !important;
                }
            }
            &:last-child{
                height: $navbar-height;
            }
        }

        .button {
            cursor: pointer;
            padding: 13px 30px;
            margin: 20px 55px;
            border: 1.5px solid $secondary;
            color: $light;
            background-color: transparent;
            text-decoration: none;
            transition: opacity .5s;
        }
    }

    .logo {
        height: 100%;

        &:nth-child(1) {
            display: block;
        }

        &:nth-child(2) {
            display: none;
        }

        img {
            position: absolute;
            width: 288px;
            height: 67px;
            left: 44px;
            top: calc(50% - 33px);
        }

        &-sm {
            height: 100%;
            align-items: center;

            img {
                height: 50%;
            }
        }
    }
}
.nav-light {
    #navbarNav {
        li a,
        .button {
            color: $text;
        }
    }

    .logo {
        height: 100%;

        &:nth-child(1) {
            display: none;
        }

        &:nth-child(2) {
            display: block;
        }
    }
}

.scrolled {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .18);
    background: #f1f1f1;
    transition: background 0.5s;

    .logo:nth-child(1) {
        display: none;
    }

    .logo:nth-child(2) {
        display: block;
    }

    #navbarNav li a,
    #navbarNav li .button {
        color: $dark;
    }
}

/* End of Navbar */

/* Start of Dropdown */
.dropdown {
    position: relative;
    display: inline-block;
    transition: box-shadow 0.1s 0.65s;
    // z-index: -1;

    &:hover {
        z-index: 0;

        .dropdown-content {
            position: absolute;
            box-shadow: inset 0px 10px 10px -10px rgb(0 0 0 / 18%);
            top: $navbar-height;
            visibility: visible;
            opacity: 1;
            z-index: -1;
            transform: translateY(0%);
            transition: all 0.35s;
        }
    }
}

.dropdown-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: $navbar-height;
    left: -20vw;
    background-color: #f1f1f1;
    width: 40vw;
    min-width: 160px;
    padding: 30px 60px;
    transform: translateY(-10%);
    z-index: -1;

    a {
      padding: 1rem 0px !important;
    }

    .title {
        font-size: 0.9rem;
        color: #3CA2DC;
        user-select: none;
    }

    .sub-title {
        color: $secondary;
        font-size: 0.75rem;
        margin-bottom: -10px;
    }

    #disabled {
        color: rgba(0, 0, 0, .3) !important;
        padding: 1rem 0 !important;
        font-size: 1rem;
        font-weight: 600;
        user-select: none;
    }

    .card {
        background-color: #222F43;
        // align-items: flex-start;
    }

    .card-text {
        font-size: 12px;
        line-height: 15px;
    }

    .card-title {
        font-weight: 300;
        font-size: 15px;
        line-height: 13px;
        color: $secondary;
        padding-bottom: 7px;
        // align-items: flex-start;
    }
    .txt-title{
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 5px;
     
    }
    .card-txt{
        font-weight: 400;
        font-size: 15px;
        // padding-left: 20px;
        line-height: 16px;
        // transition: opacity .3s;
        // margin-bottom: 0;
    }
}

/* End of Dropdown */

// Start of Home page CSS
.main-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    background-image: url("../image/mainBack.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hero {
        max-width: 712px;
        text-align: center;

        div {
            color: $light;
            user-select: none;

            span {
                background: linear-gradient(90deg, $primary 0%, $secondary 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bolder;
            }
        }

        div:first-child {
            font-weight: 300;
            font-size: clamp(2.7rem, 7vw, 5.3rem);
            line-height: 104px;
        }

        div:nth-child(2) {
            font-size: clamp(2.3rem, 5vw, 3.4rem);
            font-weight: 400;
            line-height: 67.05px;
        }
    }
    video{
     position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
}

.technologies {
    min-height: calc(100vh - #{$navbar-height});
    width: 100vw;
    position: relative;
    background: #FBFBFB;
    overflow: hidden;
    padding: 100px 0;
    display: flex;
    align-items: center;

    .title {
        font-weight: 700;
        font-size: 35px;
        line-height: 43px;
        margin-top: 6vw;
        background: linear-gradient(90deg, $secondary 0%, $primary 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-bottom: 20px;
    }

    .text {
        padding-bottom: 40px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $text;
    }

    .line {
        margin-bottom: 51px;
    }

    .button {
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color: $dark;
    }

    .card {
         width: 100%;
         max-width: 450px;          
            // user-select: none;
        //  height: ;
        // max-width: 424px;
        // box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);
        // border-radius: 20px;
        // padding: 0px;
        // padding: 36px;
        transition: all 0.35s;

        img {
            max-width:100%; 
            height: auto;           
            
        }

        &:hover {
            transform: scale(1.15);
        }
    }

    .bg {
        position: absolute;
        user-select: none;
        z-index: 0;

        &__1 {
            width: 280px;
            height: 225px;
            left: 50%;
            top: 5%;
        }

        &__2 {
            width: 115px;
            height: 128px;
            left: 300px;
            top: 30px;
        }

        &__3 {
            width: 230px;
            height: 260px;
            left: 564px;
            bottom: 59.63px;
        }
    }
}

.industeries {
    background-color: $light;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 7vw;

    .title {
        padding-top: 60px;
        font-weight: 600;
        font-size: 35px;
        line-height: 43px;
        color: $text;
    }

    .sub-title {
        padding-top: 37px;
        max-width: 817px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $text;
    }

    .row {
        padding-top: 87px;
    }

    .box-container {
        position: relative;
        width: 100%;
        padding-top: 61.2%;
        transition: all 0.35s;
        border: 3px solid transparent;
         .box {
                margin: 13px;
                // transition: 0.35s all;
            }
   
        &:hover {
            /* animation: float 0.75s infinite; */
            transform: scale(1.1);
            // transform: scale(1.10);
            transition: all 0.35s;
            z-index: 1;
            border-width: 2px;
            border-style: solid;
            border-image: linear-gradient(to right, $secondary, $primary) 1;
        }
    }

    .col-xl-4:first-child .box {
        background-image:
            linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)),
            url("../image/mining.jpg");
    }

    .col-xl-4:nth-child(2) .box {
        background-image:
            linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)),
            url("../image/city.jpg");
    }

    .col-xl-4:nth-child(3) .box {
        background-image:
            linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)),
            url("../image/construction.jpg");
    }

    .box {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        // transition: all 0.35s;
        font-size: 100%;
    }

    .text {
        height: 75%;
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        max-width: 312px;
        color: $light;
        padding-top: 1.5rem;
    }

   
}

.partners {
    min-height: 100vh;
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
    position: relative;
    background: $text;
    padding-bottom: 4vw;

    .title {
        margin: 59px auto 30px auto;
        text-align: center;
        width: 233px;
        height: 42px;
        text-align: center;
        font-weight: 600;
        font-size: 35px;
        line-height: 43px;
        background: linear-gradient(.44turn, $secondary 25%, 50%, $primary);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;

        &::before {
            content: '';
            position: absolute;
            top: 84px;
            border-top: solid 1px;
            border-image: linear-gradient(.44turn, $primary 25%, 50%, $secondary)1;
            right: calc(50% + 228px/2 + 2rem);
            width: calc(215px + 7.6rem);
        }
    }

    .text {
        margin: 0px auto 48px auto;
        padding: 0 20px 20px 20px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        max-width: 50.4rem;
        color: $light;
    }

    img {
        max-height: 50%;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        object-fit: cover;
    }

    .grid {
        max-width: 60rem;
        margin: auto;
        &-cards {
            position: relative;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 2.5rem;
            z-index: 1;
            &::before {
                content: '';
                position: absolute;
                left: -2.5rem;
                bottom: calc(45vh + 2.2rem);
                z-index: 4;
                width: 2.5rem;
                height: calc(15vh + 84px + 24px + 78px);
                border-style: solid;
                border-width: 1px;
                border-image: linear-gradient(.44turn, $primary 25%, 50%, $secondary)1;
                border-right: 0;
            }

            &-inner {
                transition: 0.35s transform;
                width: 210px;
                z-index: 5;
                height: 30vh;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                // background-image:
                // linear-gradient(150deg, $secondary, 70%, $primary),
                // linear-gradient(60deg, $primary, 70%, $secondary);
                background: linear-gradient(107deg, rgba(241,165,70,1) 0%, rgba(184,143,92,1) 51%, rgba(50,110,205,1) 100%);
                flex-direction: column;
                color: $light;
                border-radius: 20px;
                background-position: 0 0, 100% 0;
                background-repeat: no-repeat;

                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: calc(2.5rem + 1px);
                    right: -2.5rem;
                    z-index: 0;
                    border-style: solid;
                    border-top-width: 0;
                    border-right-width: 0;
                    border-left-width: 0;
                    border-bottom-width: 1px;
                    border-image: linear-gradient(.44turn, $primary 25%, 50%, $secondary)1;
                }

                &:hover {
                    transform: scale(1.1);
                }

                &:nth-child(8)::after {
                    content: '';
                    display: none;
                }

                &:nth-child(4)::after {
                    content: '';
                    right: 50%;
                    bottom: calc(-2.5rem - 2px);
                    height: 2.5rem;
                    width: 1px;
                    background-image: linear-gradient(90.1deg, #326ECD -44.28%, #F1A546 112.73%);
                }

                p {
                    font-size: 0.8rem;
                    text-align: center;
                }
                &-border{
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;
                    background: #212D40;
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);
                    padding: 2rem 1.5rem;
                    border-radius: 18px;
                }
            }
        }
    }
}

.marketplace {
    background: $light;
    min-height: calc(100vh - #{$navbar-height});
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    background-image: url("../image/img74.png");
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: center;     

    .row {
        position: relative;
        margin: auto;
        min-height: 50vh;
        background-image: linear-gradient(0.37deg, rgba(33, 45, 64, 0.69) 57.05%, rgba(33, 45, 64, 0) 107.48%), url('../image/shutterstock2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        padding: 40px 44px;
        z-index: 1;
    }

    .img-container {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        margin-bottom: 35px;
        img {
            position: relative;
            object-fit: contain;

            &:nth-child(1) {
                padding-right: 8%;
                width: 30%;
            }

            &:nth-child(2) {
                width: 70%;
            }
        }
    }

    .bg {
        position: absolute;
        width: 13vw;
        bottom: 11vw;
        right: 6vw;
        z-index: 0;
    }

    p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: $light;
        text-align: start;
        margin-bottom: 42px;
    }
}

.learnmore {
    position: relative;
    background-image: linear-gradient(90.1deg, rgba(33, 45, 64, 0.7) 27.89%, rgba(33, 45, 64, 0) 63.25%), url('../image/bg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    &>.row {
        .col {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .title {
            padding-top: 50px;
            // padding-left: 20px;
            margin-bottom: 70px;
            text-align: center;

            + .row{
                margin-bottom: 100px;
            }

            & div:first-of-type {
                font-weight: 600;
                font-size: 45px;
                line-height: 55px;
                background: linear-gradient(89.92deg, #F1A546 -2.04%, #FFFFFF 106.24%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            & div:nth-of-type(2) {
                font-weight: 500;
                font-size: 40px;
                line-height: 48px;
                color: $light;
            }
        }

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            &-img {
                height: 83px;
                width: 83px;
                margin-bottom: 14px;
                display: flex;
                align-items: center;

                img {
                    max-width: 100%;
                }
            }

            &-text {
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: $light;
                max-width: 200px;
                margin-bottom: 40px;
            }
        }
    }
}

.learn-more {
    background-color: $light  !important;
    min-height: 70vh;
    position: relative;

    .row {
        margin-bottom: 80px;
    }

    .title {
        font-size: 45px;
        line-height: 55px;
        text-align: center;
        font-weight: 400;
        padding-top: 60px;
        margin-bottom: 73px;

        span {
            background: linear-gradient(89.99deg, $secondary -2.02%, $primary 37.66%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-weight: bolder;
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-img {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            img {
                max-width: 100%;
            }
        }

        &-text {
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            max-width: 383px;
        }
    }

    .bg {
        position: absolute;

        &:nth-of-type(1) {
            width: 20vw;
            right: 2vw;
            bottom: 3vw;
        }

        &:nth-of-type(2) {
            width: 10vw;
            left: 10vw;
            top: 7vw;
        }
    }
}

.highlights {
    background: $light;
    position: relative;
    padding-top: 3.35vw;
    min-height: 70vh;
    .row {
        &:nth-child(2) {
            padding-bottom: 3.4vw
        }

        padding-left: 48px;

        &>div:nth-child(1) {
            width: max-content;
        }
    }
    .title {
        font-size: 35px;
        line-height: 42.67px;
    }

    .sub-title {
        font-weight: 300;
        font-size: 25px;
        line-height: 30px;
        width: max-content;
    }

}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.news {
    &-slider {
        z-index: 2;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;

        @media screen and (max-width: 1300px) {
            max-width: 1000px;
        }

        @media screen and (max-width: 576px) {
            margin-top: 45px;
        }

        &__wrp {
            display: flex;
            align-items: flex-start;
            position: relative;
            z-index: 0;
            max-width: 100%;
        }   

        &__item {
            width: 283px;
            height: 337px;
            flex-shrink: 0;
            border-radius: 20px;
            margin: 20px;

            &:hover{
                color: $secondary;
            }

            @media screen and (max-width: 992px) {
                width: 340px;
            }

            &.swiper-slide {
                opacity: 0;
                pointer-events: none;
                transition: all .3s;
            }

            &.swiper-slide-active,
            &.swiper-slide-prev,
            &.swiper-slide-next {
                opacity: 1;
                pointer-events: auto;
            }

        }

        &__arrow {
            justify-content: center;
            align-items: center;
            top: calc(50% - 25px);
            cursor: pointer;
            outline: none !important;
            display: flex;
            align-items: center;
            position: absolute;
            width: 50px;
            height: 50px;
            margin: auto;
            box-sizing: border-box;
            $border: 3px;
            color: $secondary;
            background: $light;
            background-clip: padding-box;
            border: solid $border transparent;
            border-radius: 50%;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                margin: -2px;
                border-radius: 50%;
                background: linear-gradient(to right, $secondary, $primary);
            }

            &:focus {
                outline: none !important;
            }

            .icon-font {
                display: inline-flex;
            }

            &.news-slider-prev {
                left: 15px;
            }

            &.news-slider-next {
                right: 15px;
            }
        }
    }

    &__item {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 30px 11px;
        color: #fff;
        border-radius: 20px;
        display: block;
        transition: all .3s;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &.first {
            background-image: linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)), url("../image/summit.png");
        }

        &.second {
            background-image: linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)), url("../image/twin.jpg");
        }

        &.third {
            background-image: linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)), url("../image/twin.jpg");
        }


        &.fourth {
            background-image: linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)), url("../image/twin.jpg");
        }

        &.fifth {
            background-image: linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)), url("../image/tuguldur.jpg");
        }

        @media screen and (min-width: 800px) {
            &:hover {
                color: $secondary;
                transition-delay: .1s;

                .news__title,
                .news__txt {
                    opacity: 1;
                    transition-delay: .1s;
                }
            }
        }



        &.active {
            color: $secondary;

            .news__title,
            .news__txt {
                opacity: 1;
            }
        }

        @media screen and (max-width: 992px) {
            padding: 30px;
        }

        @media screen and (max-width: 576px) {
            padding: 20px;
        }
    }

    &__tag {
        border: 0.7px solid $secondary;
        border-radius: 20px;
        padding: 3px 14px;
        width: max-content;
        // margin-bottom: 20px;
        margin: 14px 20px;

        span {
            background: -webkit-linear-gradient(180deg, $light 0%, $secondary 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            // font-family: 'Montserrat', sans-serif;
            // font-family: '';
        }
    }

    &__title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 5px;
        transition: opacity .3s;
        // left: 1.25rem;

        padding-left: 20px;

        @media screen and (max-width: 576px) {
            font-size: 22px;
        }

    }

    &__txt {
        font-weight: 400;
        font-size: 15px;
        padding-left: 20px;
        line-height: 16px;
        transition: opacity .3s;
        margin-bottom: 0;
    }
}
  
    

// End of Home page CSS

// #nav-light {
//     #navbarNav {
  
//       li a,
//       .buttonP {
//         color: $text;
//       }
//     }
  
//     .logo {
//       height: 100%;
  
//       &:nth-child(1) {
//         display: none;
//       }
  
//       &:nth-child(2) {
//         display: block;
//       }
//     }
//   }
  

// Start of Mapper page CSS
.mapper {
    .main-container {
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(33, 45, 64, 0.6))), url("../image/mapper.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .hero {
            max-width: 870px;
            line-height: 67px;
            font-size: clamp(2.7rem, 7vw, 3.4rem);
            color: $light;
            margin-bottom: 28px;

            span {
                display: block;
                background: linear-gradient(90deg, $secondary 0%, $light 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 700;
                // font-size: 45px;
                font-size: clamp(2.7rem, 7vw, 3.4rem);
            }
        }

        .text {
            font-size: 18px;
            line-height: 22px;
            color: $light;
            max-width: 930px;
        }
    }

    .explanation {
        background-color: #fbfbfb;
        padding-left: 5.75vw;
        padding-right: 5.75vw;
        padding-bottom: 5.75vw;

        .title {
            font-weight: 400;
            font-size: 45px;
            line-height: 55px;
            padding-top: 3vw;
            width: max-content;
            max-width: 100%;
            text-align: center;

            span {
                background: linear-gradient(90deg, $secondary 0%, $primary 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bolder;
            }
        }

        .text {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: $text;
            max-width: 800px;
            padding-top: 24px;
            padding-bottom: 5.4vw;
        }

        .item {
            position: relative;
            padding: 23px 35px;
            margin-left: 45px;
            margin-bottom: 1.2vw;

            .line {
                position: absolute;
                top: 0;
                left: 0;
            }

            .check {
                height: 42px;
                width: 45px;
                border: 2px solid $secondary;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $secondary;
                font-size: 1.75rem;
                position: absolute;
                top: 0;
                left: -45px;

                .fa {
                    -webkit-text-stroke: 3px white;
                }
            }
        }

        .img-wrapper {
            position: relative;
            width: max-content;
            max-width: 100%;

            &:hover {
                .circle {
                    &__1 {
                        transform: translateX(1rem) translateY(1rem);
                    }

                    &__2 {
                        transform: translateY(-1rem);
                    }

                    &__3 {
                        transform: translateX(-1rem);
                    }
                }
            }

            img {
                position: relative;
                z-index: 1;
            }

            .circle {
                position: absolute;
                border-radius: 50%;
                transition: all 0.35s;

                &__1 {
                    width: 18vw;
                    height: 18vw;
                    bottom: -4vw;
                    right: 0;
                    background: linear-gradient(135deg, $light 0%, $secondary 100%);
                }

                &__2 {
                    width: 14vw;
                    height: 14vw;
                    top: -7vw;
                    left: calc(50% - 7vw);
                    background: linear-gradient(180deg, $primary 0%, $light 100%);
                    transition-delay: 0.1s;
                }

                &__3 {
                    width: 10.313vw;
                    height: 10.313vw;
                    left: 0;
                    top: calc(50% - 5.15vw);
                    background: linear-gradient(135deg, $primary 0%, $light 100%);
                    transition-delay: 0.2s;
                }
            }
        }
    }

    .keyfeatures {
        min-height: 70vh;
        background-color: $light;
        padding: 68px 5.75vw 56px 5.75vw;

        .title {
            font-weight: 400;
            font-size: 45px;
            line-height: 55px;

            span {
                font-weight: 600;
                background: linear-gradient(90deg, $secondary -9.83%, $primary 39.41%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }

        .img-container {
            position: relative;
            width: 100%;
            height: max-content;

            .circle {
                position: absolute;
                border-radius: 50%;
                z-index: 0;
                transition: all 0.35s;

                &:nth-of-type(1) {
                    background: linear-gradient(135deg, rgba($color: $secondary, $alpha: 0.75) 0%, rgba($color: $secondary, $alpha: 0) 100%);
                    width: 16.5vw;
                    height: 16.5vw;
                    top: 10%;
                    left: 0;
                }

                &:nth-of-type(2) {
                    background: linear-gradient(135deg, rgba($color: $primary, $alpha: 0) 0%, rgba($color: $primary, $alpha: 0.75) 100%);
                    width: 7.5vw;
                    height: 7.5vw;
                    bottom: 0;
                    left: 50%;
                }
            }

            img {
                position: relative;
                max-width: 100%;
                margin: auto 0;
                z-index: 1;
            }

            &:hover {
                .circle {
                    &:nth-of-type(1) {
                        transform: translateX(-20px) translateY(-20px);
                    }

                    &:nth-of-type(2) {
                        transform: translateX(20px) translateY(20px);
                    }
                }
            }
        }

        .item {
            display: flex;
            margin-bottom: 56px;

            &-img {
                width: 75px;
                margin-right: 50px;
                align-self: center;

                img {
                    max-width: 65px;
                }
            }

            &-text {
                font-size: 18px;
                line-height: 22px;
                color: $text;
                max-width: 514px;
            }

            &:first-of-type {
                .item-img img {
                    max-width: 75px;
                }
            }
        }
    }

    .industeries {
        // min-height: calc(100vh - 100px);
        background: $text;
        padding-bottom: 0;
        background-image: url('../image/mapper-indus-bg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-attachment: fixed;
        min-height: 100vh;
        .title {
            background: linear-gradient(90deg, $secondary 0%, $light 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 40px;
            padding-top: 30px;
        }

        .row {
            padding: 0;
            margin: 0;
        }

        .wrapper {
            width: 100%;
            padding: 0 100px;
        }

        .line {
            position: relative;
            top: 50%;
            width: unset;
            flex-grow: 1;
            height: 1.2px;
            background: linear-gradient(90deg, $primary 0%, $secondary 100%);
        }

        .line-wrapper {
            height: 80px;
            position: relative;

            .line-h {
                position: absolute;
                right: 19%;
                display: inline-block;
                width: 1.2px;
                height: 100%;
                background: linear-gradient(180deg, $primary 0%, $secondary 100%);
            }

            &:first-of-type {
                width: 100px;
                position: absolute;
                top: -64px;

                .line-h {
                    height: 64px;
                    right: -2px;
                    width: 2px;
                }

                .line {
                    height: 2px;
                    width: 22vw;
                    top: 0;
                    right: -100%;
                }
            }
        }

        .card {
            background-color: $text;
            padding: 28px 23px;
            display: inline-block;
            position: relative;
            width: 100%;
            border-radius: 20px;
            background: linear-gradient(90deg, $secondary 0%, $primary 100%);
            border: none;
            transition: all 0.35s;
            z-index: 2;

            &:hover {
                transform: scale(1.12);
                z-index: 3;
            }

            &::after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                background-color: $text;
                z-index: -2;
                border-radius: 18px;
            }

            .row {
                padding-top: 18px;
                margin: 0;
                width: max-content;
                display: inline-flex;
            }

            &-title {
                font-weight: 600;
                font-size: 25px;
                line-height: 30px;
                text-align: left;
                background: linear-gradient(90deg, #F1A546 0%, #FFFFFF 89.51%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            &-txt {
                padding-left: 28px;
                list-style: none;
                text-decoration: none;
                font-size: clamp(16px, 1vw, 18px);
                line-height: 22px;
                color: $light;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: start;
            }

            &-image {
                padding: 0;
                width: 182px;
                height: 118px;

                img {
                    max-width: 100%;
                    border-radius: 20px;
                }
            }
        }

        .text {
            max-width: none;
            padding-top: 116px;
            margin-bottom: 40px;
            text-align: center;
            display: block;
        }

        @media only screen and (max-width: 1199.99px) {
            .wrapper>.row {
                flex-direction: column;
                padding: 0 !important;
            }

            .line,
            .line-wrapper {
                display: none;
            }

            .card {
                margin-bottom: 30px;
            }
        }
    }
}

// End of Mapper page CSS
// Start of Platform page CSS
.platform {

    &>div {
        padding: 0 4.2vw;
    }

    .main-container {
        // margin-left: 15px;
        padding-top: $navbar-height;
        background-image: url('../image/back.jpg');
        min-height: 100vh;
        height: unset;
        justify-content: flex-start; 

        .wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 82vw;
            min-height: 60vh;
            border-radius: 20px;
            margin: 0 auto 50px auto;
            // margin-bottom: 30px;
            overflow: hidden;

            background: transparent;
            border-style: solid;
            border-width: 2px;
            border-image: linear-gradient(to right, $secondary, $primary) 1;

            .hero {
                margin-top: 4vw;
                font-size: clamp(2.7rem, 7vw, 3rem);
                max-width: 900px;
                color: $light;
                margin-bottom: 20px;

                span {
                    display: block;
                    font-weight: bolder;
                    background: linear-gradient(90deg, $secondary 0%, $light 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .text {
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;
                color: $light;
                background: rgba($color: $dark, $alpha: .41);
                border-radius: 20px;
                padding: 50px;
                max-width: 1000px;
                margin-bottom: 36px;
            }

            .button {
                margin-bottom: 60px;
            }
        }

        .btnn {
            display: inline-flex;
            width: 274px;
            height: 55px;
            background-image: url('../image/Rectangle\ 118.png');
            background-repeat: no-repeat;
            background-size: contain;
            justify-content: center;
            align-items: center;
            
            span {
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                background: linear-gradient(90deg, $secondary 0%, $light 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .sub-title {
            width: max-content;
            font-size: 17px;
            line-height: 21px;
            font-weight: 600;
            color: $secondary;
            margin-bottom: 0px;
        }

        #disabled {
            width: max-content;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #B7B7B7;
        }
    }

    .architecture {
        background-color: $light;
        min-height: calc(100vh - #{$navbar-height});
        position: relative;

        .row {
            position: relative;
            z-index: 1;
        }

        .title {
            color: $text;
            font-weight: 500;
            font-size: 35px;
            line-height: 43px;
            padding-top: 5vw;
            padding-bottom: 4vw;
            text-align: center;

            span {
                background: linear-gradient(90deg, $primary 0%, $secondary 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bold;
            }
        }

        .img-wrapper {
            position: relative;
            background: linear-gradient(90deg, $primary 0%, $secondary 100%);
            border-radius: 20px;
            margin-bottom: 22px;

            img {
                background-color: $light;
                padding: 15px;
                margin: 2px;
                border-radius: 18px;
                width: calc(100% - 4px);
            }
        }

        .text {
            padding-top: 4vw;
            margin-bottom: 4vw;
            max-width: 586px;
        }

        .colorful-text {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;

            span {
                background: linear-gradient(90deg, $secondary 0%, $primary 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .bg {
            position: absolute;
            z-index: 0;

            &:nth-of-type(1) {
                width: 120px;
                height: 150px;
                left: 150px;
                top: 40px;
            }

            &:nth-of-type(2) {
                position: absolute;
                height: 236px;
                right: 100px;
                bottom: 100px;
            }
        }
    }

    .data {
        background: #FCFCFC;
        position: relative;

        &>.row:first-of-type {
            padding-top: 3.5vw;
        }

        .title {
            font-weight: 400;
            font-size: 35px;
            line-height: 43px;
            max-width: 460px;
            margin-bottom: 40px;
            text-align: center;

            span {
                background: linear-gradient(90deg, $secondary 0%, $primary 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bold;
            }
        }

        .item {
            width: 100%;
            background: linear-gradient(90deg, $secondary 0%, $primary 100%);
            border-radius: 20px;
            padding: 2px;

            &-inner {
                background: $light;
                width: 100%;
                height: 100%;
                border-radius: 18px;
                padding: 0 43px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                &-text {
                    font-family: 'Audiowide';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 28px;
                }

                .logo {
                    display: flex;
                    align-items: center;
                    width: 88px;
                    height: 88px;
                    padding: 0;
                    display: block !important;

                    img {
                        max-width: 100%;
                    }
                }

                .row {
                    margin: 35px 0;
                    flex-wrap: nowrap;

                    .col {
                        display: flex;  
                        align-items: center;
                    }
                }
            }
        }
        .bg{
            position: absolute;
            width: 45vw;
            left: 27px;
            top: -7px;
            img{
                max-width: 100%;
            }
        }
    }

    .value {
        min-height: 74vh;
        background: #fbfbfb;
        position: relative;
        padding-top: 4vw;
        .row {
            padding: 2.5vw 0;
        }

        .title {
            font-weight: 500;
            font-size: 85px;
            line-height: 104px;
            background: linear-gradient(90deg, #F1A546 -6.98%, #326ECD 105.68%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            transform: rotate(-90deg);

            &-sm {
                font-size: 60px;
                transform: none;
                margin-bottom: 20px;
            }
        }

        .img-wrapper {
            width: 120px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .items {
            &>.row:last-of-type {
                padding-bottom: 2.5vw;
            }
        }

        .item {
            &-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 20px;
            }

            &-text {
                max-width: 770px;
            }
        }

        .bg {
            position: absolute;
            &:nth-of-type(1) {
                width: 102px;
                height: 83px;
                top: 39px;
                right: 25%;
                transform: rotate(94.87deg);
            }

            &:nth-of-type(2) {
                height: 340px;
                bottom: 53px;
                right: 100px;
            }
            &:nth-of-type(3){
                 width: 90px;
                height: 100px;
                left: 330px;
                top: 120px;
            }
        }
    }

    .geospatial {
        background: $light;
        position: relative;

        .title {
            font-weight: 600;
            font-size: 45px;
            line-height: 55px;
            padding-top: 5vw;
            margin-bottom: 4vw;
            text-align: center;

            span {
                background: linear-gradient(90deg, $primary 0%, $secondary 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bolder;
            }
        }

        .card {
            background: linear-gradient(90deg, $secondary 0%, $primary 100%);
            padding: 2px;
            border-radius: 20px;
            border: none;
            margin-bottom: 5vw;

            &-inner {
                background-color: $light;
                border-radius: 18px;
                padding: 67px 37px;

                .item {
                    &:first-of-type {
                        margin-bottom: 60px;
                    }

                    img {
                        width: 60px;
                        margin-bottom: 27px;
                    }

                    .text {
                        max-width: 600px;
                    }
                }

                .off-img {
                    position: relative;
                    left: -110%;
                    width: 200%;
                }
            }
        }

        .bg {
            position: absolute;
            width: 100%;
            max-height: 100%;
            top: 0;
            right: 0;
        }
    }

    .keyfeatures {
        position: relative;
        background: $light;
        padding-bottom: 2vw;
        .title {
            font-weight: 400;
            font-size: 40px;
            line-height: 49px;
            padding: 4vw 0;

            span {
                background: linear-gradient(90deg, $secondary 0%, $primary 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bolder;
            }
        }

        .sub-title {
            font-weight: 500;
            font-size: 35px;
            line-height: 43px;
            width: max-content;
        }

        .item {
            display: flex;
            align-items: center;
            margin-bottom: 70px;

            &-img {
                display: inline-block;
                width: 64px;
                height: 64px;
            }

            &-text {
                max-width: 500px;
                margin-left: 60px;
            }
        }
    }

    .remote {
        background: #fcfcfc;   
        position: relative;
        .title {
            position: relative;
            font-weight: 600;
            font-size: 35px;
            line-height: 43px;
            margin-bottom: 28px;
            width: 520px;
            color: $text;
            background-color: #fcfcfc;
            z-index: 1;
        }

        .sub-title {
            font-weight: 400;
            font-size: 35px;
            line-height: 43px;
            color: $primary;
            max-width: 300px;
            span {
                font-weight: 600;
            }
        }

        .text {
            max-width: 558px;
            padding-bottom: 123px;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            color: $text;
        }

        .box-wrapper {
            // height: calc(100vh - #{$navbar-height});
            // height: 100vh;
            display: grid;
            position: relative;
            grid-template-columns: repeat(2, 252px);
            grid-template-rows: repeat(5, 1fr);
            gap: 30px;
            padding: 50px 0px;
            width: max-content;
            grid-template-areas:
                "first ."
                "first second"
                "fourth second"
                "fourth third"
                ". third";

            .line-h {
                position: absolute;
                height: 70%;
                width: 2px;
                background: linear-gradient(180deg, $primary 0%, $secondary 100%);

                &-1 {
                    top: 152.5px;
                    right: 126px;
                }

                &-2 {
                    left: 126px;
                    bottom: 152.5px;
                }

            }

            .line {
                position: absolute;
                // width: calc(100vw - 4.2vw - 20px - 1.5rem - 300px - 252px - 39px - 126px);
                height: 2px;

                &-1 {
                    width: 50vw;
                    right: 126px;
                    top: 152.5px;
                }

                &-2 {
                    width: 252px;
                    bottom: 152.5px;
                    left: 126px;
                }
            }
        }

        .box {
            position: relative;
            width: 252px;
            background: #F1F1F1;
            border-radius: 20px;
            padding: 17px 25px;
            z-index: 1;
            box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.15);

            &:nth-of-type(1) {
                grid-area: first;
            }

            &:nth-of-type(2) {
                grid-area: second;
            }

            &:nth-of-type(3) {
                grid-area: third;
            }

            &:nth-of-type(4) {
                grid-area: fourth;
            }

            &-title {
                font-weight: 600;
                font-size: 30px;
                line-height: 37px;
                margin-bottom: 11px;
                color: $secondary;
            }

            .img-wrapper {
                width: 80px;
                height: 80px;
                margin-bottom: 3.2vw;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .bg{
            position: absolute;
            width: 45vw;
            left: 27px;
            top: -7px;
            img{
                max-width: 100%;
            }
        }
        .off-img {
            position: absolute;
            left: 100px;
            top: 380px;
            // top: 50px;
            // justify-content: center;
            img{
                max-width: 25%;
            }
        }
    }

    .elaborate {
        padding-top: 63px;
        padding-bottom: 63px;

        .title {
            font-weight: 300;
            font-size: 40px;
            line-height: 48.41px;
            margin-bottom: 5vw;
            color: $light;
            max-width: 795px;

            span {
                font-weight: 600;
                font-size: 51px;
                line-height: 62px;
                background: linear-gradient(90.11deg, $secondary 7.14%, $primary 103.61%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }

        .box {
            background: linear-gradient(90deg, $secondary 0%, $primary 100%);
            padding: 2px;
            border-radius: 20px;
            height: 100%;
            border: none;

            &-inner {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 36px 26px;
                background-color: $text;
                border-radius: 18px;
                height: 100%;

                .item {
                    &-text {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: $light;
                        margin-bottom: 23px;
                    }

                    .img-wrapper {
                        width: 52px;
                        margin-bottom: 19px;

                        img {
                            max-width: 100%;
                        }
                    }
                }
            }

            &-sm {
                width: 80%;
                background: linear-gradient(90deg, $secondary 0%, $primary 100%);
                padding: 2px;
                border-radius: 20px;
                border: none;
                flex: 1 1 auto;

                .box-inner {
                    padding: 46px;
                    padding-bottom: 5.5vw;

                    .logo {
                        width: 70px;
                        margin-bottom: 33px;

                        img {
                            max-width: 100%;
                        }
                    }

                    .box-title {
                        font-size: 17px;
                        line-height: 22px;
                        margin-bottom: 16px;
                        color: $light;
                        font-family: 'Audiowide';

                        span {
                            display: block;
                            font-family: inherit;
                            font-size: 40px;
                            line-height: 51px;
                        }
                    }

                    .box-text {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        margin-bottom: 50px;
                        color: $light;
                    }

                    .big-img {
                        position: absolute;
                        width: 190%;
                        min-width: 100px;
                        left: -9vw;
                        top: -9vw;
                    }
                }
            }
        }
    }
}

// End of Platform page CSS

// Start of Team page CSS
.team {
    .main-container {
        position: relative;
        align-items: flex-end;
        background-image: linear-gradient(269.72deg, rgba(33, 45, 64, 0.79) 35.95%, rgba(33, 45, 64, 0) 115.75%),
            url("../image/DSC01437.jpg");

        .wrapper {
            padding: 44px 57px;
            border: 1px solid black;
            position: relative;
            background: rgba(196, 196, 196, 0.01);
            backdrop-filter: blur(40px);
            max-width: 850px;
            border-right-width: 0;
            border-image: linear-gradient(to right, $secondary, $primary) 1;
            
            .title {
                font-size: 45px;
                line-height: 55px;
                margin-bottom: 27px;
                color: $light;

                span {
                    background: linear-gradient(90.11deg, $secondary 7.14%, $light 103.61%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    font-weight: bold;
                }
            }

            .text {
                font-weight: 200;
                font-size:23px;
                line-height: 30px;
                max-width: 709px;
                padding-bottom: 40px;
                color: $light;
            }
        }
    }

    .intro {
        background-color: $light;
        background-image: url("../image/ex1.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        padding: 4vw 7.5vw;
        text-align: center;
        .title {
            display: inline-block;
            font-weight: bold;
            text-align: center;
            // padding: 0 550px;
            font-size: 35px;
            line-height: 43px;
            margin-bottom: 17px;
            background: linear-gradient(90.11deg, $secondary 0%, $primary 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            width: max-content;
            
        }

        .sub-title {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            margin-bottom: 3vw;
            margin-top: calc(-27px - 3vw);
            top: 0;
            left: 0;
            // margin-left: calc(85vw - 1000px) ;
            color: $text;
            position: absolute;
        }

        .text {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 2.5vw;
            // max-width: 645px;
            color: $text;
        }

        .item-wrapper {
            padding-top: calc(27px + 3vw);
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
            justify-content: center;
            gap: 5vw;
            margin-bottom: 3.4vw;

            .item {
                display: flex;
                justify-content: center;
                background-color: red;
                position: relative;
                width: 100%;
                height: max-content;
                padding-top: 105%;
                margin-bottom: 10px;
                background: transparent;
                border-width: 2px;
                border-style: solid;
                border-image: linear-gradient(to right, $secondary, $primary) 1;
                // transition: all 0.35s;

                &-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 13.71px;
                    overflow: hidden;
                    background: linear-gradient(180deg, #EDEDED 0%, #E1E1E1 100%);
                    box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.02);

                    img {
                        position: relative;
                        margin: auto;
                        z-index: 2;
                        max-height: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    .circle {
                        position: absolute;
                        transition: all 0.35s;
                        margin: 0;
                        transform: none;

                        &:nth-of-type(2) {
                            width: 57%;
                            z-index: 1;
                            left: 5%;
                            top: 3%;
                        }

                        &:nth-of-type(3) {
                            width: 35%;
                            z-index: 0;
                            bottom: 30%;
                            left: unset;
                            right: 5%;
                        }
                    }
                }

                &-text {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: $text;

                    &>div:nth-child(2) {
                        margin-bottom: 9.5px;
                    }

                    &>div:last-child {
                        font-weight: 400;
                    }
                }
                
            }
        }

        .bg {
            position: absolute;
            width: 100%;
            max-height: 100%;
            top: 0;
            right: 0;
        }
    }
}

// End of Team page CSS

// Start of Mission page CSS
.mission {
    &>div {

        padding: 0 calc(5.5vw + 30px);
        position: relative;

        &:last-of-type {
            padding: unset;
        }
    }

    .main-container {
        background-image: url("../image/img74.png");
        background-color: #f5f5f5;
        background-size: contain;

        .title {
            font-weight: 400;
            font-size: 40px;
            line-height: 50px;
            max-width: 750px;
            margin-bottom: 32px;

            span {
                background: linear-gradient(89.79deg, $secondary -5.13%, $primary 86.47%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                font-weight: bold;
            }
        }

        .text {
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            max-width: 750px;
            color: $text;
        }
    }

    .value {
        min-height: calc(100vh - #{$navbar-height});
        background-color: #fbfbfb;

        .title {
            font-size: 35px;
            line-height: 43px;
            margin-bottom: 18px;

            span {
                font-weight: bold;
                background: linear-gradient(90deg, $secondary -9.83%, $primary 39.41%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }

        .text {
            font-size: 15px;
            line-height: 18px;
            max-width: 710px;
            margin-bottom: 44px;
        }

        .item {
            position: relative;
            z-index: 1;
            background: linear-gradient(90deg, $secondary 0%, $primary 100%);
            border-radius: 20px;
            padding: 2px;
            max-width: 568px;
            margin-bottom: 46px;

            &-inner {
                display: flex;
                align-items: center;
                padding: 30px 40px;
                background-color: #fbfbfb;
                border-radius: 18px;

                &-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                    line-height: 18px;
                    margin-left: 3vw;
                    color: $text;
                }

                .img-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 54px;
                    height: 54px;

                    img {
                        // max-width: 100%;
                        width: 54px;
                        flex-grow: 0;
                    }
                }
            }
        }

        .bg {
            position: absolute;
            z-index: 0;
            top: 0;
            right: 0;
            height: 100%;
        }
    }

    .vision {
        background: #F5F5F5;
        padding-top: 3vw;
        padding-bottom: 4vw;

        .box {
            margin: auto;
            width: 100%;
            max-width: 1100px;
            background: linear-gradient(90deg, $secondary 0%, $primary 100%);
            padding: 2px;
            border-radius: 20px;

            &-inner {
                background: #F5F5F5;
                padding: 36px;
                border-radius: 18px;

                &-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-width: 750px;
                    margin: auto;

                    .title {
                        font-size: 35px;
                        line-height: 43px;
                        margin-bottom: 20px;
                        text-align: center;
                        font-weight: bold;

                        span {
                            background: linear-gradient(90deg, $secondary -9.83%, $primary 39.41%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                        }
                    }

                    .text {
                        max-width: 694px;
                        margin-bottom: 2.56vw;
                    }

                    .video-wrapper {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 4vw;

                        i {
                            position: absolute;
                            color: $light;
                            font-size: clamp(2.7rem, 4vw, 5.3rem);
                            z-index: 2;
                        }

                        .overlay {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background: rgba(33, 45, 64, 0.7);
                            z-index: 1;
                        }

                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .bg {
            position: absolute;

            &:nth-of-type(2) {
                width: 124px;
                top: 60px;
                left: 20px;
            }

            &:nth-of-type(1) {
                width: 124px;
                right: 36px;
                bottom: 100px;
            }
        }
    }

    .case {
        padding-top: 4vw;
        padding-bottom: 5vw;
        background-image: url("../image/img74.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $light;   

        .title {
            font-weight: 600;
            font-size: 35px;
            line-height: 43px;
            margin-bottom: 30px;
            text-align: center;
            color: $text;
        }

        .text {
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            margin: auto;
            margin-bottom: 3vw;
            color: $text;
            max-width: 880px;
        }

        .news-slider {
            padding: 50px 0;
        }

        .news-slider__item {
            width: 331px;
            height: 202px;
            transition: all 0.35s !important;
            border: 3px solid transparent;

            &:hover {
                transform: scale(1.1) !important;
                padding: 10px;
                transition: all 0.35s;
                z-index: 1;
                // border: 2px solid;
                border-width: 2px;
                border-style: solid;
                border-image: linear-gradient(to right, $secondary, $primary) 1;
            }

            .news {
                &__item {
                    border-radius: 0;
                    justify-content: flex-end;

                    &.fifth {
                        background-image: linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)), url("../image/back.jpg");
                        // background-image: linear-gradient(rgba($color: $text, $alpha: 0.6)), url("../image/back.jpg");
                    }

                    &.first {
                        background-image: linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)), url("../image/mapper.jpg") // background-image: linear-gradient(rgba($color: $text, $alpha: 0.6)), url("../image/mapper.jpg");
                    }

                    &.second,
                    &.third,
                    &.fourth {
                        background-image: linear-gradient(to bottom, rgba(33, 45, 64, 0), rgba(33, 45, 64, 1)), url("../image/shutterstock1.jpg");
                        // background-image: linear-gradient(rgba($color: $text, $alpha: 0.6)), url("../image/shutterstock1.jpg");
                    }
                }

                &__title {
                    font-family: 'Audiowide', sans-serif;
                    font-size: 20px;
                    line-height: 28px;
                    padding: 0;
                    width: max-content;
                    margin: 0 auto;
                    text-transform: uppercase;
                }

                // &__subtitle {
                //     font-family: 'Audiowide', sans-serif;
                //     font-size: 25px;
                //     font-weight: 400;
                //     line-height: 32px;
                //     width: max-content;
                //     margin: 0 auto;
                // }

                &__button {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    justify-content: center;
                    margin: 30px auto 0 auto;
                    width: max-content;
                    padding: 6px 24px;
                }
            }
        }
    }
}

// End of Mission page CSS

// Start of Tag page CSS
.tag {
    .main-container {
        background-image: linear-gradient(269.72deg, rgba(33, 45, 64, 0.79) 35.95%, rgba(33, 45, 64, 0) 115.75%), url("../image/shutterstock1.jpg");

        .wrapper {
            position: relative;
            align-items: center;
            width: 82vw;
            min-height: 60vh;
            padding: 5vw 40px;
            border-radius: 20px;
            margin-bottom: 30px;
            background: transparent;
            border-style: solid;
            border-width: 2px;
            border-image: linear-gradient(to right, $secondary, $primary) 1;

            .hero {
                font-weight: 400;
                font-size: 45px;
                line-height: 55px;
                margin-bottom: 46px;
                color: $light;

                span {
                    display: block;
                    width: max-content;
                    background: linear-gradient(90deg, $secondary 0%, $light 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .text {
                max-width: 681px;
                font-size: 15px;
                line-height: 18px;
                margin-bottom: 5vw;
                color: $light;
            }

            .desc {
                position: relative;
                background: linear-gradient(90deg, #F1A546 -34.6%, #FFFFFF 103.11%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                align-self: center;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                max-width: 354px;

                .line {
                    position: absolute;
                    right: -110%;
                    top: calc(50% - 2px);
                }
            }

            .img-wrapper {
                position: absolute;
                right: 0;
                bottom: -3vw;
                max-width: 40%;

                img {
                    max-width: 120%;
                }
            }
        }
    }

    .keyfeatures {
        background: $light;
        padding: 6vw 0;

        .title {
            font-size: 45px;
            line-height: 55px;
            max-width: 377px;
            margin-bottom: 30px;

            div {
                font-weight: 600;
                background: linear-gradient(90deg, #F1A546 -9.83%, #326ECD 39.41%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }

        .position-relative {
            img {
                position: absolute;
                max-width: 100%;
                max-height: calc(100vh - #{$navbar-height});
                left: 0;
            }
        }

        .box {
            background: linear-gradient(90deg, $secondary 0%, $primary 100%);
            padding: 2px;
            border-radius: 20px;
            max-width: 456px;
            border: none;

            &-inner {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 36px 26px;
                background-color: $light;
                border-radius: 18px;


                .item {
                    &-text {
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;
                        color: $text;
                        margin-bottom: 2vw;
                    }

                    .img-wrapper {
                        width: 52px;
                        margin-bottom: 19px;

                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
// End of Tag page CSS

// Start of Mining page CSS
.mining {
    .main-container {
        position: relative;
        align-items: flex-end;
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(33, 45, 64, 0.6))), url("../image/AdobeStock2.jpg");


        .wrapper {
            padding: 44px 57px;
            border: 1px solid black;
            position: relative;
            background: rgba(196, 196, 196, 0.01);
            backdrop-filter: blur(40px);
            max-width: 850px;
            border-right-width: 0;
            border-image: linear-gradient(to right, $secondary, $primary) 1;

            .title {
                font-size: 45px;
                line-height: 55px;
                margin-bottom: 27px;
                color: $light;

                span {
                    background: linear-gradient(90.11deg, $secondary 7.14%, $light 103.61%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    font-weight: bold;
                }
            }

            .text {
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                max-width: 630px;
                color: $light;
            }
        }
    }

    .explanation {
        background-color: #fbfbfb;
        padding: 5.75vw;
        position: relative;
        overflow: hidden;

        .item {
            position: relative;
            padding: 23px 35px;
            margin-left: 45px;
            margin-bottom: 1.2vw;
            font-size: 15px;
            line-height: 18px;
            max-width: 550px;

            .line {
                position: absolute;
                top: 0;
                left: 0;
            }

            .check {
                height: 42px;
                width: 45px;
                border: 2px solid $secondary;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $secondary;
                font-size: 1.75rem;
                position: absolute;
                top: 0;
                left: -45px;

                .fa {
                    -webkit-text-stroke: 3px white;
                }
            }
        }

        .bg {
            position: absolute;
            width: 40%;
            left: 0;
            bottom: -60%;

            img {
                max-width: 100%;
            }
        }
    }

    // .boxes {
    //     background: #F5F5F5;
    //     position: relative;
    //     padding: 0 5.75vw;
    //     padding-top: 5.6vw;

    //     &>.row {
    //         padding-bottom: 5.75vw;
    //     }

    //     .box {
    //         position: relative;
    //         max-width: 800px;
    //         width: 100%;
    //         background: linear-gradient(90deg, $secondary 0%, $primary 100%);
    //         padding: 2px;
    //         border-radius: 20px;
    //         border: none;
    //         z-index: 1;

    //         &-title {
    //             font-weight: 600;
    //             font-size: 20px;
    //             line-height: 24px;
    //             margin-bottom: 16px;
    //             text-align: left;
    //             width: max-content;
    //             max-width: 100%;

    //             background: linear-gradient(90deg, $secondary 0%, $primary 89.51%);
    //             -webkit-background-clip: text;
    //             -webkit-text-fill-color: transparent;
    //             background-clip: text;
    //         }

    //         &-inner {
    //             background-color: #F5F5F5;
    //             border-radius: 18px;
    //             padding: 26px;
    //         }

    //         &-text {
    //             font-size: 15px;
    //             line-height: 18px;
    //         }

    //         &-img {
    //             max-width: 228px;

    //             img {
    //                 max-width: 100%;
    //             }
    //         }
    //     }

    //     .bg {
    //         position: absolute;
    //         z-index: 0;

    //         &:nth-of-type(1) {
    //             top: 115px;
    //             right: 5.75vw;
    //             max-width: 280px;
    //         }

    //         &:nth-of-type(2) {
    //             max-width: 123px;
    //             top: calc(50% - 75px);
    //             left: 5.75vw;
    //             transform: rotate(180deg) scaleX(-1);
    //         }

    //         img {
    //             max-width: 100%;
    //         }
    //     }
    // }
}

// End of Mining page CSS


// Start of City page CSS
.city {
    background: #F5F5F5;

    .main-container {
        position: relative;
        align-items: flex-start;
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(33, 45, 64, 0.6))), url("../image/AdobeStock3.jpg");

        .wrapper {
            padding: 44px 57px;
            border: 1px solid black;
            position: relative;
            background: rgba(196, 196, 196, 0.01);
            backdrop-filter: blur(40px);
            max-width: 850px;
            border-left-width: 0;
            border-image: linear-gradient(to right, $secondary, $primary) 1;

            .title {
                font-size: 45px;
                line-height: 55px;
                margin-bottom: 27px;
                color: $light;

                span {
                    background: linear-gradient(90.11deg, $secondary 7.14%, $light 103.61%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    font-weight: bold;
                }
            }

            .text {
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                max-width: 688px;
                color: $light;
            }
        }
    }

    .boxes {
        // background-image: linear-gradient(rgb(255,255,255)) , url("../img//image\ 74.png");
        // background-image: url("../img//image\ 74.png");
        background-size: contain;
        position: relative;
        padding: 0 5.75vw;
        padding-top: 5.6vw;

        &>.row {
            padding-bottom: 5.75vw;

            &:nth-child(1) {
                .box::after {
                    right: -15vw;
                    top: 50%;
                    border-width: 2px;
                    border-left-width: 0;
                    border-bottom-width: 0;
                    border-image: linear-gradient(to right, $primary, $secondary) 1;
                    border-style: solid;
                }
            }

            &:nth-child(2) {
                .box::after {
                    left: -15vw;
                    top: 50%;
                    border-width: 2px;
                    border-right-width: 0;
                    border-bottom-width: 0;
                    border-image: linear-gradient(to right, $primary, $secondary) 1;
                    border-style: solid;
                }
            }
        }

        .box {
            position: relative;
            max-width: 800px;
            width: 100%;
            background: linear-gradient(90deg, $secondary 0%, $primary 100%);
            padding: 2px;
            border-radius: 20px;
            border: none;

            &-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 16px;
                text-align: left;
                width: max-content;
                max-width: 100%;

                background: linear-gradient(90deg, $secondary 0%, $primary 89.51%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            &-inner {
                background-color: #F5F5F5;
                border-radius: 18px;
                padding: 26px;
            }

            &-text {
                font-size: 15px;
                line-height: 18px;
            }

            &-img {
                max-width: 228px;

                img {
                    max-width: 100%;
                }
            }

            &::after {
                content: '';
                width: 15vw;
                height: calc(50% + 5.75vw);
                position: absolute;
            }
        }

        .bg {
            position: absolute;
            width: 100%;
            max-height: 100%;
            top: 0;

            img {
                max-width: 100%;
            }
        }
    }
}

// End of City page CSS

// Start of Study page CSS
.study {
    &>div {
        padding: 0 5.75vw;
    }

    .main-container {
        background-image: linear-gradient(rgba($color: $text, $alpha: 0.6)), url("../image/image\ 76.png");
        background-position: center;
        background-size: 90vw;
        background-repeat: no-repeat;

        .hero {
            max-width: 100%;
            line-height: 67px;
            font-size: clamp(2.7rem, 7vw, 45px);
            margin-bottom: 26px;
            color: $light;

            span {
                display: block;
                background: linear-gradient(90deg, $secondary 0%, $light 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 700;
                font-size: 55px;
            }
        }

        .text {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            max-width: 710px;
            text-align: center;
            color: $light;
        }

        .sub-title {
            font-weight: 500;
            font-size: 22px;
            line-height: 27px;
            color: $light;
        }

        .bg {
            position: absolute;
            width: 100%;
            max-height: 100%;
            top: 0;
        }
    }

    .drougth {
        position: relative;
        padding: 5.75vw;
        background: $light;

        .text {
            font-size: 15px;
            line-height: 22px;
            color: $text;
            margin-bottom: 35px;
            max-width: 1035px;
        }

        .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;
            color: $text;
        }

        img {
            max-width: 710px;
            width: 100%;
            padding-bottom: 6vw;
        }

        .row {

            .text,
            .title {
                max-width: 628px;
            }
        }

        .bg {
            position: absolute;
            width: calc(100vw - 710px);
            min-width: 50vw;
            max-width: unset;
            top: 5.75vw;
            right: -25%;
        }

        .bg-1 {
            position: absolute;
            width: calc(80vw);
            object-fit: contain;
            max-width: unset;
            max-height: 993px;
            top: 0;
            right: -20vw;
        }
    }
}

// Start of Career page CSS
.career {
    .main-container {
        position: relative;
        align-items: flex-end;
        background-image: linear-gradient(269.72deg, rgba(33, 45, 64, 0.79) 35.95%, rgba(33, 45, 64, 0) 115.75%),
            url("../image/DSC01437.jpg");

        .wrapper {
            padding: 44px 57px;
            border: 1px solid black;
            position: relative;
            background: rgba(196, 196, 196, 0.01);
            backdrop-filter: blur(40px);
            max-width: 850px;
            border-right-width: 0;
            border-image: linear-gradient(to right, $secondary, $primary) 1;

            .title {
                font-size: 45px;
                line-height: 55px;
                margin-bottom: 27px;
                color: $light;

                span {
                    background: linear-gradient(90.11deg, $secondary 7.14%, $light 103.61%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    font-weight: bold;
                }
            }

            .text {
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                max-width: 709px;
                padding-bottom: 40px;
                color: $light;
            }
        }
    }

    .intro {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        position: relative;
        background: url("../../assets/image/career-back.png") center right/contain;
        background-color: #F5F5F5;

        .inner-container {
            max-width: 85vw;
            display: flex;
            margin: auto;
            flex-direction: column;
            margin: 2rem 6rem 2rem 6rem;

            .title {
                font-weight: bold;
                font-size: 2.1rem;
                line-height: 2.6rem;
                margin: 1rem 0 2rem 0;
                background: linear-gradient(90.11deg, $secondary 0%, $primary 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                width: max-content;
            }

            .toolbar {
                margin: 3rem 0 3rem 0;
                display: flex;
                flex-direction: row;
                justify-items: center;
                align-items: center;

            }

            .toolbar li {
                list-style: none;
                margin-right: 4rem;
                padding: 0.7rem 1.7rem;
                align-items: center;
                justify-content: center;
            }

            .toolbar li a {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.1rem;
                color: #212D40;
            }

            .active-toolbar {
                padding: 0.7rem 1.7rem;
                border: 2px solid #326ECD;
            }

            .table-container {
                margin: 2rem 0 2rem 0;
                border-width: 2.5px;
                border-style: solid;
                background: rgba(196, 196, 196, 0.01);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                backdrop-filter: blur(35px);
                padding: 2rem 3rem;
                border-image: linear-gradient(90.1deg, #326ECD -44.28%, #F1A546 112.73%) 1;

                table {
                    width: 100%;
                    border-spacing: 1;
                    border-collapse: collapse;
                    background: transparent;
                    /* border-radius:6px; */
                    overflow: hidden;
                    width: 100%;
                    margin: 0 auto;
                    position: relative;
                }

                table * {
                    position: relative
                }

                table td,
                th {
                    padding-left: 1rem;
                }

                table tbody tr {
                    height: 4.5rem;
                    border-bottom: 1.5px solid #C7C7C7;
                }

                table td th {
                    text-align: left;
                }

                table td th .l {
                    text-align: left;
                }

                table td th .c {
                    text-align: center;
                }

                table td th .r {
                    text-align: right;
                }

                table td th a:hover {
                    cursor: pointer;
                }
            }

            .primary-text {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 24px;
                /* identical to box height, or 141% */
                color: #326ECD;
            }

            .table-body-text {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 24px;
                /* identical to box height, or 160% */
                color: #212D40;
            }

            .table-second-text {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                /* identical to box height, or 160% */

                color: #212D40;
            }

            .table-third-text {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                /* identical to box height, or 160% */
                color: #888888;
            }

            .table-forth-text {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                /* identical to box height, or 160% */
                color: #888888;

            }
        }
    }
}
// End of Career page CSS

// Start of Contact page CSS
.contact{
    .main-container{
      height: unset;
      width: unset;
      min-height: 100vh;
      background-color: #F5F5F5;
      background-image: url("../image/img74.png");
  
      .card{
        border-image: linear-gradient(to right, $secondary, $primary) 1;
        border-width: 2.5px;
        max-width: 592.18px;
        padding: 40px 53px;     
        padding-right: 29px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(35px);
        background: rgba(196, 196, 196, 0.01);
  
        & > *{
          padding-right: 1.5rem;
        }
  
        &-title{
          margin: 0 auto;
          margin-bottom: 20px;
          max-width: 398.36px;
          font-size: 22px;
          line-height: 27px;
        }
        .form-control, .form-select{
          background-color: transparent;
        }
        .form-control, .form-select, .form-check-input{
          border-radius: 0;
          border-color: $text;
          max-height: 33px;
        }
        label{
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: $text;
        }
        .line{
          width: calc(100% - 1.5rem);
          height: 1.2px;
          margin: 53.5px 0 39.5px 0;
        }
        .button{
          padding: 5px 12px;
          margin-bottom: 52px;
          margin-top: 42px;
        }
        .col-md-6{
          margin-top: 40px;
  
          &:nth-child(odd){
            padding-left: 0;
            padding-right: 20px;
          }
          &:nth-child(even){
            padding-left: 20px;
            padding-right: 0;
          }
        }
      }
      
      .title{
        font-weight: 400;
        font-size: 45px;
        line-height: 55px;
        margin-top: 158px;
        margin-bottom: 16px;
        text-align: center;
        color: $text;
  
        div{
          font-family: 'Audiowide';
          width: max-content;
          background: linear-gradient(-90deg, $primary 0%, $secondary 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: 0 auto;
          font-weight: bolder;
        }
      }
      .text{
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $text;
        margin-bottom: 73px;
        max-width: 419px;
      }
      .address{
        position: relative;
        font-size: 18px;
        line-height: 30px;
        padding-left: 35px;
  
        .line{
          width: 1.5px;
          height: unset;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background: linear-gradient(0deg, #f1a546 0%, #326ecd 100%);
        }
      }
    }
  }
// End of Contact page CSS

// Start of Apply page CSS
.apply{
    & > div{
      padding: 0 8.8vw;
    }
    .main-container{
      background: #F5F5F5;
      display: flex;
      height: unset;
      width: unset;
      min-height: 100vh;
      flex-direction: column;
      align-items: flex-start;
    //   .box div{
    //     text-align: center;

    //     .d-flex{
    //       justify-content: space-around;
    //     }
    //     .line{
    //       max-width: unset;
    //     }
    //   }
      
      .title-wrapper{
        align-self: center;
        width: 100vw;
        background-image: url("../image/Image74.2.png");
        background-color: $text;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        margin-bottom: 4vw;
        padding: 80px;
        margin-top: $navbar-height;
      }
      .title{
        width: 100%;
        background: linear-gradient(89.79deg, $secondary -5.13%, $light 86.47%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-weight: 600;
        font-size: 35px;
        line-height: 50px;
        text-align: center;
      }
      .sub-title{
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-top: 66px;
        margin-bottom: 35px;
        color: $text;
        &:first-child{
          margin-top: 0;
        }
      }
      .text{
        font-size: 15px;
        line-height: 24px;
        max-width: 987px;
      }
      ul{
        list-style: square;
        &:last-child{
          margin-bottom: 8vw;
        }
        
        li{
          padding-left: 20px;
          margin-bottom: 20px;
          font-size: 15px;
          line-height: 24px;
          max-width: 594px;
        }
      }
      .box{
        max-width: 429px;
        width: 100%;
        height: max-content;
        border-width: 3px;
        border-style: solid;
        border-image: linear-gradient(to right, $secondary, $primary) 1;
        padding: 2.5vw 3.3vw;
  
        .button{
          padding: 10.5px 18.5px;
          font-weight: 500;
          font-size: 17px;
          line-height: 21px;
          color: $text;
  
          &-blue{
            padding: 12.5px 20.5px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }
  
        &-title{
          margin-top: 3vw;
          margin-bottom: 22px;
          font-weight: 400;
          font-size: 25px;
          line-height: 30px;
  
          span{
            font-weight: 600;
          }
        }
        &-text{
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 1rem;
        }
        &-link{
          display: block;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 27px;
          color: #326ECD;
        }
        .line{
          margin-bottom: 29px;
          max-width: 244.07px;
        }
        .d-flex{
          margin-bottom: 30px;
          .box-text-sm{
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #888888;
  
            &:nth-child(1){
              padding-right: 30px;
            }
          }
        }
      }
    }
    .resume{
      background-color: #F2F2F2;
      background-image: url("../image/img74.png");
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding-bottom: 8vw;
  
      .title{
        width: max-content;
        text-align: center;
        margin: 0 auto;
        font-weight: 700;
        padding-top: 82px;
        margin-bottom: 70px;
        font-size: 35px;
        line-height: 43px;
        background: linear-gradient(90.09deg, $secondary -2.69%, $primary 106.39%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      .box{
        display: flex;
        flex-direction: column;
        min-height: 694px;
        margin: 0 auto;
        align-items: center;
        max-width: 592.18px;
        border-width: 3px;
        border-style: solid;
        border-image: linear-gradient(to right, $secondary, $primary) 1;
        padding: 40px 68px;
  
        &-title{
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 63px;
          text-align: center;
        }
        .dropbox{
          width: 100%;
          padding: 20px;
          min-height: 226px;
          margin-bottom: 28px;
          text-align: center;
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: dashed 1px #000;
          border-image: url("http://i.stack.imgur.com/wLdVc.png") 1 round;
          border-image-source: url("http://i.stack.imgur.com/LKclP.png");
  
          &-text{
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 0.75rem;
  
            &-sm{
              font-size: 15px;
              line-height: 18px;
              margin-bottom: 1.6rem;
            }
          }
  
          img{
            width: 44px;
            margin-bottom: 8px;
          }
        }
        .line{
          margin: 43px 0;
        }
        .item{
          align-self: flex-start;
          display: flex;
          justify-content: space-between;
          max-width: 214px;
          width: 100%;
          border: 1px solid $text;
          padding: 6px 10px;
  
          span{
            font-size: 13px;
            line-height: 16px;
            color: #969696;
          }
        }
        .button{
          padding: 10.5px 34px;
        }
        .form-check{
          margin-bottom: 60px;
  
          &-input{
            border-radius: 0;
            border-color: $text;
            max-height: 33px;
          }
        }
      }
    }
  }
// End of Apply page CSS
// Start of Login page CSS
.login{
    .main-container{
      height: unset;
      width: unset;
      min-height: 100vh;
      background-color: #F5F5F5;
      background-image: url('../image/img74.png');
  
      .card{
        background: rgba(196, 196, 196, 0.01);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(35px);
        border-image: linear-gradient(to right, $secondary, $primary) 1;
        border-width: 2.5px;
        display: flex;
        width: 510px;
        flex-direction: column;
        align-items: center;
  
        &-img{
          margin: 30px;
          width: 90px;
          height: 90px;
  
          img{
            max-width: 100%;
          }
        }
        .button{
          padding: 5px 35px;
        }
        .email{
          margin-bottom: 21px;
        }
        a{
          width: max-content;
          padding: 0;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          text-align: center;
          color: #5194A9;
        }
        .line{
          width: 105px;
          margin-bottom: 20px;
        }
        .google{
          background: #FFFFFF;
          border-radius: 20px;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          text-align: center;
          color: $text;
          border: none;
          width: max-content;
          padding: 13px 20px;
          margin-bottom: 89px;
  
          img{
            width: 22px;
            height: 22px;
          }
        }
        form{
          .col-12{
            max-width: max-content;
          }
        }
        .form-control, .form-select{
          background-color: transparent;
        }
        .form-control, .form-select, .form-check-input{
          border-radius: 0;
          border-color: $text;
          max-height: 33px;
          width: 310px;
        }
        label{
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: $text;
        }
      }
    }
  }
//End of Login page CSS

//Start of ForgotPass page CSS
.forgotpass{
    .main-container{
      height: unset;
      width: unset;
      min-height: 100vh;
      background-color: #F5F5F5;
      background-image: url("../image/img74.png");
    }
    .card{
      background: rgba(196, 196, 196, 0.01);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(35px);
      border-image: linear-gradient(to right, $secondary, $primary) 1;
      border-width: 2.5px;
      display: flex;
      width: 510px;
      flex-direction: column;
      align-items: center;
  
      &-img{
        margin: 30px;
        width: 90px;
        height: 90px;
  
        img{
          max-width: 100%;
        }
      }
      .text{
        max-width: 342px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: $text;
        padding: 0;
      }
      .button{
        padding: 5px 35px;
        margin-bottom: 120px;
      }
      #email{
        margin-bottom: 21px;
      }
      a{
        width: max-content;
        padding: 0;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #5194A9;
      }
      form{
        .col-12{
          max-width: max-content;
        }
      }
      .form-control, .form-select{
        background-color: transparent;
      }
      .form-control, .form-select, .form-check-input{
        border-radius: 0;
        border-color: $text;
        max-height: 33px;
        width: 310px;
      }
      label{
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: $text;
      }
    }
  }
// End of ForgotPass page CSS
// Start of feature page CSS
// .feature-container{
//     height: 100vh;
//     width:100vw;
    
//     overflow: hidden;
//     background: url("../image/career-back.png")  center right/contain;
//     position: relative;
//     /* background: url('../img/career-back.png') no-repeat center/contain; */
//     /* background-size: cover; */
//   }
//   .feature-grid{
//     /* width: 100%; */
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//   }
//   .feature-sub-container-1{
//     margin:0 3.5rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100vh;
//     flex-direction: column;
//   }
//   .feature-sub-title{
//     padding-top: 2rem;
//     padding-bottom: 2rem;
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-weight: 500;
//     font-size: 35px;
//     line-height: 43px;
//     text-align: justify;
//   /* identical to box height */
  
//     color: #212D40;
  
//   }
//   .feature-sub-title span{
//     font-family: 'Montserrat';
//     font-weight:bolder;
//     text-align: center;
//   background: linear-gradient(90.09deg, #F1A546 -2.69%, #326ECD 106.39%);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   background-clip: text;
//   }
//   .feature-sub-description{
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 15px;
//   line-height: 25px;
//   margin-bottom: 3rem;
//   }
//   .feature-sub-btn{
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 500;
//   font-size: 17px;
//   line-height: 21px;
//   /* identical to box height */
//   color: #212D40;
//   flex: none;
//   order: 0;
//   font-weight: bold;
//   align-self: start;
//   padding: 0.5rem 2rem;
//     border-width: 2px;
//     margin: 1rem 0;
//     border-style: solid;
//     border-image: linear-gradient(90.1deg, #f08706 -44.28% , #326ECD 112.73%) 1;
//   }
//   .feature-sub-btn:hover{
//     cursor: pointer;
//   }
  
//   .feature-sub-container-2{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-top: 5rem;
    
//     height: 70vh;
//     border-right-width:2px;
//     /* border-width: 2px; */
//       border-style: solid;
     
//       border-image: 
//       linear-gradient(#326ECD, #F1A546) 0 100%;
//   }
//   .image-card{
//     position: relative;
//     margin: 2rem 0rem;
//     width: 24rem;
//     height: auto;
//   }
//   .margin{
//     margin:0 3.5rem;
//   }
//   .scene-title{
//     font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 24px;
//   }
//   .border{
//     background: url("../image/botder.png");
//     position: absolute;
//     bottom: 4px;
//     width: 469px;
//     height: 57px;
//     padding: 1rem;
//     display: flex;
//     align-items: center;
//     justify-content: start;
//     border-bottom-right-radius: 1.8rem;
//     border-bottom-left-radius: 1.8rem;
//     width: 24rem;
//   }
//   .border-title{
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 600;
//   color: #fff;
//   font-size: 0.7rem;
//   line-height: 18px;
//   }
//   .image-card img {
//     border-radius: 1.8rem;
//     object-fit: cover;
//     width: 100%;
//     height: 100%;
//   }
// End of Feature page Css
@media (max-width: 576px) {
    .logo {
        display: none !important;
    }

    .navbar-brand {
        width: 100px;
    }
}

@media (max-width: 768px) {
    .mapper {
        .industeries {
            .card {
                width: 75%;
            }
        }
    }

    .highlights {
        .row {
            padding: 0;
            margin: auto;
        }
    }

    .dropdown-content {
        max-height: 50vh;
        overflow-y: auto;
    }

    .architecture {
        min-height: unset !important;
    }
}

@media (min-width: 768px) {
    .tag {
        .wrapper {
            &>div:first-child {
                width: 60% !important;
            }
        }

        // .keyfeatures{
        //   .box{
        //     width: 60% !important;
        //   }
        // }
    }
}

@media (max-width: 992px) {
    .mapper {
        .industeries {
            .card {
                &-title {
                    text-align: center;
                }

                &-txt {
                    padding-left: 0;
                }

                .row {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }

            li {
                text-align: center;
            }
        }
    }

    .partners {
        .grid-cards {
            grid-template-columns: repeat(2, 40vw) !important;
            justify-content: center;
            gap: 1.5rem !important;
            margin-bottom: 6vw;
            &-inner {
                width: 100% !important;
            }
        }

        .text {
            text-align: center !important;
        }
    }

    .navbar {
        &:hover {
            .navbar-collapse.show {
                background: rgba($color: $light, $alpha: 1);
            }
        }

        &-nav {
            display: grid;
            grid-template-columns: repeat(2, 45vw);
            justify-items: start;
            justify-content: center;
            align-items: center;
            width: 100%;

            & .nav-item {
                width: 100%;
                height: $navbar-height  !important;
                justify-content: center;
            }

            & .nav-link {
                color: $dark  !important;
                margin: 0 !important;
                padding: 0;
                width: 100%;
                height: 100%;
                text-align: center;
            }

            .button {
                color: $dark  !important;
            }
        }

        &-collapse {
            transition: background 0.35s;

            // border-bottom-left-radius: 20px;
            // border-bottom-right-radius: 20px;
            &.show {
                background: rgba($color: $light, $alpha: 0.75);
            }
        }
    }

    .scrolled .navbar-collapse.show {
        background: rgba($color: $light, $alpha: 1);
    }

    .dropdown {
        &:nth-child(2) {
            .dropdown-content {
                left: -45vw;
            }
        }

        &-content {
            width: 90vw;
            left: 0;
        }
    }

    #city {
        .boxes {
            .box {
                &::after {
                    content: none;
                }
            }
        }
    }
}

@media (max-width: 1067px) {
    .partners {
        .title {
            &::before {
                display: none;
            }
        }

        .grid-cards {
            &::before {
                display: none;
            }
        }

        .grid-cards-inner {
            &::after {
                display: none;
            }
        }
    }
}

@media (min-width: 1200px) {
    .mapper {
        .industeries {
            .card {
                min-width: 470px;
            }
        }
    }
}
@media (max-width: 1366px){
    .apply{
      .main-container{
        .title-wrapper{
          background-size: auto 100%;
        }
      }
    }
  }
