.Footer{
    overflow: hidden;
    position: relative;
    height: 60vh;
    width: 100vw;
    background-color: #f5f5f5;
  }
  
  .FooterImg1{
  position: absolute;
  width: 150px;
  height: 120px;
  right: 5rem;
  top: 2.5rem;
  /* transform: rotate(-90deg); */
    background: url("../../assets/image/footer1.png") no-repeat center right/contain;
  }
  .FooterImg2{
    position: absolute;
    width: 85.3px;
    height: 100px;
    left: 90px;
    top: 433px;
    transform: rotate(180deg);
    background: url("../../assets/image/footer1.png") no-repeat center right/contain;
  }
  .FooterGrid
  {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr;
     */
     grid-template-columns: repeat(4, 1fr);
    margin: 7rem 3rem;
  }
  .FooterFlex{
    position: relative;
    margin-left: 8rem;
    display: flex;
    flex-direction: column;
    /* margin-left: 4rem; */
    align-items: flex-start;
    border-right-width:2px;
    
    /* border-width: 2px; */
      border-right-style: solid;
     height: 18rem;
      border-image: 
      linear-gradient(#326ECD, #F1A546) 0 100%;
    justify-content: flex-start;
  }
  .FooterFlex:nth-child(4){
    position: relative;
    border:none;
  }
  .FooterTitle{
    /* width: 100px; */
  height: 24px;
  font-family: 'Montserrat' sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: justify;
  background: linear-gradient(90.16deg, #F1A546 -3.25%, #326ECD 103.06%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  }
  .FooterDesc{
    text-align: justify;
    margin-top: 2rem;
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #212D40;
  }
 .FooterDesc:hover{
  color: #F1A546;
 }
  .IconFlex{
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
  }
  
  .FooterIcon{
    margin-right: 2rem;
    height: 35px;
   /* margin: 0 0 0 ; */
    /* margin: 0 2rem; */
  
  }
  .FooterIcon img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* margin: 20px 5px; */
  }
  .ImageFooter {
    position: absolute;
    width: 356px;
    top: 5rem;
    left: -1.5rem;
  }
  .DetailTitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    
    color: #212D40;
  }